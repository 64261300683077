<template>
  <div class="product-review-container">
    <div class="pc">
      <template v-if="reviews && reviews.length>0">
        <div class="h8">후기 {{ allCount }}개</div>
        <div class="lp-divider-sub5 margin-top-16 margin-bottom-16"></div>
        <div v-for="(review,idx) in reviews" :key="'theme-review-'+idx" class="item-review">
          <div style="flex:1">
            <div class="flex" style="justify-content: space-between">
              <div class="flex-align" style="gap:8px">
                <img src="/static/icon/avata.svg">
                <div class="body4 sub2">{{ review.author }}</div>
              </div>
              <div class="body5 sub3">{{ datesFormat(review.created_time, 'date_3') }}</div>
            </div>
            <div class="body5 sub3" style="margin:12px 0">{{ review.title }}</div>
            <div class="body4 main">{{ review.content }}</div>
          </div>
          <img v-if="review.imgs.length>0" class="img-product" :src="review.imgs[0]" @click="clickImg(review)">
        </div>
        <!-- 페이지네이션 -->
        <lp-paginate :filtered.sync="filtered" style="margin-top:88px" @click="getData"></lp-paginate>
      </template>
      <div v-if="reviews && reviews.length===0" class="empty-view">아직 등록된 후기가 없습니다.</div>
    </div>

    <div class="mobile" style="padding:0 16px">
      <template v-if="reviews && reviews.length>0">
        <div v-for="(review,idx) in reviews" :key="'theme-review-'+idx" class="item-review">
          <div class="flex-between margin-bottom-12">
            <div class="flex-align" style="gap:8px">
              <img src="/static/icon/avata.svg">
              <div class="body4 sub2">{{ review.author }}</div>
            </div>
            <div class="body5 sub3">{{ datesFormat(review.created_time, 'date_3') }}</div>
          </div>
          <div v-if="review.title !== ''"
            class="body5 sub3 margin-bottom-12">{{ review.title }}</div>
          <div v-if="review.imgs.length>0"
               class="flex-align drag-scroll-mobile margin-bottom-12">
            <img v-for="(img, idx) in review.imgs" :key="`review-img-${idx}`"
              class="img-product" :src="img">
          </div>
          <div class="body4 main">{{ review.content }}</div>
        </div>
        <!-- 페이지네이션 -->
        <lp-paginate :filtered.sync="filtered" :unit="5" class="padding-top-32" @getData="getData"></lp-paginate>
      </template>
      <div v-if="reviews && reviews.length===0" class="empty-view">아직 등록된 후기가 없습니다.</div>
    </div>
    <image-modal ref="imgModal" :imgs="selectedImgs"></image-modal>
  </div>
</template>
<script>
  import LpPaginate from "../component/LpPaginate";
  import ImageModal from "../component/ImageModal";
  export default {
    name: "ProductReview",
    props: {
    },
    components: {ImageModal, LpPaginate},
    created() {
      if(this.$route.query.id) {
        this.getData();
      }
    },
    data() {
      return {
        formData: [],
        reviews: [],
        filtered: {
          cur_page: 1,
          page_num:1,
          total_page:1,
          page_length: 9
        },
        // 전체 카운트 수
        allCount: 0,
        selectedImgs: []
      }
    },
    methods: {
      clickImg(review) {
        this.selectedImgs = review.imgs;
        this.$refs.imgModal.open();
      },
      getData() {
        let url = `public/product/${this.$route.query.id}/review`;
        this.$axios.get(url,{
          params: this.filtered
        }).then(res=>{
          if(res.status === 200) {
            this.reviews = res.data.data;
            this.filtered.page_length = res.data.page_length;
            this.filtered.cur_page = res.data.cur_page;
            this.filtered.total_page = res.data.total_page;
            this.allCount = res.data.count;

            // test
            /*this.reviews = [
              {
                author: '이남호',
                content: '플랫폼의 후기입니다. 이렇게 저렴한 가격에 플랫폼을 개설하다니 너무 좋아요!\n' +
                  '후기는\n' +
                  '말줄임없이 모두 표시\n' +
                  '남길 수 있어요.',
                product: '6개월 정기결제',
                created_time: '2022-06-25',
                imgs: [
                  "https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/product/29010338.png"
                ]
              }
            ]*/
          }
        });
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .product-review-container
    padding-bottom 40px
  .lp-divider-gray3
    border-bottom 12px solid $gray3

  .pc
    .item-review
      display flex
      gap 0 40px
      padding 16px 0
      border-bottom 1px solid $gray2

    .img-product
      object-fit cover
      object-position center
      width 100px
      height 100px
      border-radius 8px

  .empty-view
    padding-bottom 80px

  .mobile
    .item-review
      padding 20px 0
      border-bottom 1px solid $gray2
    .img-product
      object-fit cover
      object-position center
      width 88px
      height 88px
      border-radius 8px
      margin-right 8px

</style>
