<template>
  <div v-if="detail">
    <div :style="pcStyle">
      <div class="container">
        <div class="product-wrapper">
          <!-- 상품정보 좌 -->
          <div>
            <div class="card-img" :style="imgField(detail.img, 'pc')"></div>
          </div>
          <!-- 상세정보 우 -->
          <div>
            <span class="flex-align body5 sub3">
                <span class="unselect" @click="routerPush(marketList)">{{ detail.category1_name }}</span>
                <img style="width:15px;height:15px" class="svg-sub5" src="/static/icon/fi_chevron-right.svg">
                <span class="unselect" @click="routerPush(marketList)">{{ detail.category2_name }}</span>
            </span>
            <div class="subtitle3 main padding-top-8">{{ detail.title }}</div>
            <div class="body3 sub2 padding-top-8">{{ detail.content }}</div>
            <div class="flex-align" style="gap:16px;margin-top:16px">
              <div class="flex-align" style="gap:4px">
                <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:14px">
                <div class="body4 sub3">조회수 {{ detail.visit|currencyNum }}</div>
              </div>
              <div class="flex-align" style="gap:4px">
                <img src="/static/icon/u_comment-alt-lines.svg" class="svg-sub3" style="width:14px">
                <div class="body4 sub3 unselect" style="text-decoration: underline" @click="clickReview"
                >후기 {{ detail.reviews_count|currencyNum }}개</div>
              </div>
            </div>
            <!-- 미리보기 버튼 -->
            <div class="button-group-preview" v-if="detail.preview_url">
              <button class="button is-primary-light2" @click="showMobilePreview">
                <img src="/static/icon/u_mobile-android-alt.svg" alt="" class="unselect"
                     style="width:20px;height:20px">
                <span class="body2-medium margin-left-12">모바일 미리보기</span>
              </button>
              <a :href="detail.preview_url" target="_blank">
                <button class="button is-primary-light2">
                  <img src="/static/icon/fi_monitor.svg" alt="" class="unselect"
                       style="width:20px;height:20px">
                  <span class="body2-medium margin-left-12">데스크톱 미리보기</span>
                </button>
              </a>
            </div>

            <market-option-selector :product="detail"></market-option-selector>
          </div>
        </div>
        <!-- 상품 상세 -->

        <!-- 관련 상품 -->
        <block-related-product v-if="detail.related_products.length>0"
                               :products="detail.related_products"></block-related-product>

        <!-- 탭 -->
        <tab ref="tab" :menu="tabs" :style="`margin-top:${detail.related_products.length > 0 ? '40' : '100'}px`">
          <template v-slot:tab0>
            <div v-if="detail.imgs && detail.imgs.length>0" style="margin-top:64px;padding:0 75px">
              <div class="content-slide" v-if="detail.imgs">
                <slick ref="slick" :options="slickOptions">
                  <div v-for="(img, idx) in detail.imgs"
                       :key="'slide-'+idx" class="slick-img-outer">
                    <img :src="img" alt="" class="slick-img" :style="slickImg">
                  </div>

                </slick>
              </div>
            </div>
            <div class="sun-editor-editable content column is-12" id="anchor0" v-html="detail.info">{{ detail.info }}</div>
          </template>
          <template v-slot:tab1>
            <div class="content column is-12" style="margin-top:64px">
              <product-review></product-review>
            </div>
          </template>
          <template v-slot:tab2>
            <div class="content column is-12" style="margin-top:64px">
              <product-inquiry></product-inquiry>
            </div>
          </template>
        </tab>
      </div>
    </div>
  </div>

</template>

<script>
  import Slick from 'vue-slick'
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import ProductInquiry from "./ProductInquiry";
  import Tab from "../module/Tab";
  import MarketOptionSelector from "../component/MarketOptionSelector";
  import ProductReview from "./ProductReview";
  import BlockRelatedProduct from "../component/BlockRelatedProduct";

  export default {
    name: "MarketDetail",
    mixins: [
      UserAPIMixin,
    ],
    components: {
      BlockRelatedProduct,
      ProductReview,
      MarketOptionSelector,
      Tab,
      ProductInquiry,
      Slick
    },
    data() {
      return {
        tabs: [
          {id: 0, name: '서비스 안내'}, {id: 1, name: '후기'}, {id: 2, name: '이용문의'}
        ],
        // 상세 상품
        detail: undefined,
        slickOptions: {
          centerMode: false,
          variableWidth: false,
          infinite: true,
          autoplay: false,
          centerPadding:'20px',
          slidesToShow: 3,
          slidesToScroll: 1,
          speed: 600,
          prevArrow: '<div class="arrow-left"><img class="arrow-img" src="/static/icon/fi_chevron-left.svg" @click="prev"></div>',
          nextArrow: '<div class="arrow-right"><img class="arrow-img" src="/static/icon/fi_chevron-right.svg" @click="next"></div>',
          responsive: [
            {
              breakpoint: 767,
              settings: {
                autoplay: true,
                slidesToShow: 1,
                centerMode: true,
                dots: false,
                arrows: false
              }
            }
          ]
        }
      }
    },
    watch:{
      '$route'(n){
        if(n.query.id) {
          this.getProductDetail();
        }
      }
    },
    created() {
      if(this.$route.query.id) {
        this.getProductDetail();

        if(this.isMobile) {
          this.slickOptions.variableWidth = false;
          this.slickOptions.centerMode = false;
          this.slickOptions.autoplay = true;
        }
      }
    },
    computed: {
      marketList() {
        return this.$route.path === '/market/detail' ? '/market/list' : '/app_market/list';
      },
      pcStyle() {
        return this.$route.path === '/market/detail' ? 'margin-top:130px' : '';
      },
      slickImg() {
        if(window.innerWidth>500) {
          let width = 318;
          let height = 483;
          let margin = '0 6px';
          return {
            width: width+'px',
            height: height+'px',
            margin: margin,
            borderRadius: '8px'
          }
        } else {
          let width = window.innerWidth - 24.5;
          let height = width * 1.31;
          let padding = '0 6px';
          return {
            width: width+'px',
            height: height+'px',
            padding: padding,
            borderRadius: '8px'
          }
        }
      },
      contentSlideStyle() {
        if(window.innerWidth<500) {
          return {
            width: '100%',
            height: this.slickImg.height
          }
        } else {
          return {
            width: '1106px',
            height: this.slickImg.height
          }
        }
      }
    },
    methods: {
      clickReview() {
        this.$refs.tab.selectedItem = 1;
        location.replace('#tabs');
      },
      showMobilePreview() {
        window.name = 'preview_parent';
        let popup = window.open('', 'previewPopup', 'width=360, height=640, top=300, left=300, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=yes, scrollbar=no');
        popup.location.href = this.detail.preview_url;
      },
      preview(device) {
        console.log(device)
        location.href = this.detail.preview_url;
      },
      next() {
        this.$refs.slick.next();
      },

      prev() {
        this.$refs.slick.prev();
      },
      // 상품 상세
      getProductDetail() {
        this.$axios.get(`public/launchpack/v1/theme/${this.$route.query.id}`).then(async res=>{
          if(res.status === 200) {
            if(res.data.preview_url && res.data.preview_url.indexOf('demo')===-1) {
              let key = res.data.preview_url.split('.')[0].replace('https://','');
              res.data.preview_url += `/home?demo_test=${key}`;
            }

            // 대표이미지 제외
            //res.data.imgs = res.data.imgs.splice(1);
            // 슬라이드쇼 반복을 위해서 concat
            // res.data.imgs = res.data.imgs.concat(res.data.imgs);

            res.data.selectors.forEach(item => {
              item.options.forEach(child => {
                if (child.is_discount && !child.origin_price) {
                  child.origin_price = child.price;
                  child.price = child.price * (1 - (child.discount_rate / 100));
                }
              });
            });

            let detail = res.data;
            if(!detail.service_use_price) {
              detail.service_use_price = {
                id: 1,
                name: "서비스 운영 이용료",
                desc: null,
                price: 300000
              }
            }
            await this.setServiceUsePrice(detail);
            this.detail = detail;
          }
        });
      },
      async setServiceUsePrice(detail) {
        let pack_name = '';
        let item = this.findItem(detail.params, 'name', '주문전환');
        if(item) {
          if(item.value_select && item.value_select.length>0) {
            let pack_item = this.findItem(item.choice_items, 'id', item.value_select[0]);
            if(pack_item) {
              pack_name = pack_item.name;
            }
          }
          let query = '';
          if(pack_name) {
            query = `?pack=${this.convertPack(pack_name)}`
          }
          await this.$axios.get(`public/launchpack/service_use_price/info${query}`).then(res=>{
            detail.service_use_price = res.data;
          })
        }
      },
      convertPack(name) {
        switch (name) {
          case '': return '';
          case '전화연결': return 'call';
          case '아웃링크': return 'outlink';
          case '상품배송주문': return 'delivery';
          case '모임신청': return 'meet';
          case '상담신청': return 'counsel';
          case '견적요청': return 'estimate';
          case '사용권': return 'license';
          case '컨텐츠다운로드주문': return 'commerce';
          case '숙박예약': return 'booking';
          case '서비스예약': return 'reservation';
        }
      },
      imgField(img, device) {
        let style = {
          backgroundImage: `url(${this.image(img)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        };
        if(device==='mobile') {
          style.height = (window.innerWidth * 0.7).toString() +'px';
        }
        return style;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .product-wrapper
    display grid
    grid-gap 0 72px
    grid-template-columns repeat(2, 1fr)

  .card-img
    width 516px
    height 516px
    border-radius 8px


  .button-group-preview
    display flex
    margin-top 20px
    gap 12px
    a
    .button
      width 100%
      flex 1
      display flex
      align-items center
    img
      filter invert(54%) sepia(46%) saturate(5884%) hue-rotate(360deg) brightness(100%) contrast(110%)

</style>
